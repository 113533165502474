var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo"},[_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v(" Pemantauan Sembako ")])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"mb-1"},[_vm._v(" Buat Akun ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" Masukkan data detail anda untuk buat akun ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.checkIfRecaptchaVerified.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":"Nama","label-for":"register-nama"}},[_c('validation-provider',{attrs:{"name":"Nama","vid":"nama","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-nama","name":"register-nama","state":errors.length > 0 ? false:null,"placeholder":"nama"},model:{value:(_vm.nama),callback:function ($$v) {_vm.nama=$$v},expression:"nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Provinsi","label-for":"register-provinsi"}},[_c('validation-provider',{attrs:{"name":"Provinsi","vid":"provinsi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false:null},on:{"input":function($event){return _vm.getkabkot(_vm.kode_prov)}},model:{value:(_vm.kode_prov),callback:function ($$v) {_vm.kode_prov=$$v},expression:"kode_prov"}},_vm._l((_vm.dataprovinsi),function(dataprovinsi,k){return _c('option',{key:k,domProps:{"value":dataprovinsi.id}},[_vm._v(" "+_vm._s(dataprovinsi.nama)+" ")])}),0),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Kabupaten","label-for":"register-kabupaten"}},[_c('validation-provider',{attrs:{"name":"Kabupaten/Kota","vid":"kabupaten","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false:null},on:{"input":function($event){return _vm.getkecamatan(_vm.kode_kab)}},model:{value:(_vm.kode_kab),callback:function ($$v) {_vm.kode_kab=$$v},expression:"kode_kab"}},_vm._l((_vm.datakabupaten),function(datakabupaten,k){return _c('option',{key:k,domProps:{"value":datakabupaten.id}},[_vm._v(" "+_vm._s(datakabupaten.nama)+" ")])}),0),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Kecamatan","label-for":"register-kecamatan"}},[_c('validation-provider',{attrs:{"name":"Kecamatan","vid":"kecamatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.kode_kec),callback:function ($$v) {_vm.kode_kec=$$v},expression:"kode_kec"}},_vm._l((_vm.datakecamatan),function(datakecamatan,k){return _c('option',{key:k,domProps:{"value":datakecamatan.id}},[_vm._v(" "+_vm._s(datakecamatan.nama)+" ")])}),0),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"register-email"}},[_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"register-email","name":"register-email","state":errors.length > 0 ? false:null,"placeholder":"john@example.com"},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":"Password"}},[_c('validation-provider',{attrs:{"name":"Password","vid":"password","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password"}},[_c('vue-recaptcha',{attrs:{"sitekey":"6Le-wvkSAAAAAPBMRTvw0Q4Muexq9bi0DJwx_mJ-"},on:{"verify":_vm.markRecaptchaAsVerified}})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit","disabled":invalid}},[_vm._v(" Sign up ")]),_c('div',[_c('strong',[_vm._v(_vm._s(_vm.pleaseTickRecaptchaMessage))])])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v("Sudah punya akun ?")]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" Login")])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }