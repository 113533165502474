<style scoped>
@media
	  only screen 
    and (max-width: 760px), (min-device-width: 768px) 
    and (max-device-width: 900px)  {
      .brand-logo {
        visibility: hidden;
      }
    }
</style>
<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->

        <h2 class="brand-text text-primary ml-1">
          Pemantauan Sembako
        </h2>
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title class="mb-1">
            Buat Akun
          </b-card-title>
          <b-card-text class="mb-2">
            Masukkan data detail anda untuk buat akun
          </b-card-text>

          <!-- form -->
          <validation-observer
            ref="registerForm"
            #default="{invalid}"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="checkIfRecaptchaVerified"
            >
              <!-- nama -->
              <b-form-group
                label="Nama"
                label-for="register-nama"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nama"
                  vid="nama"
                  rules="required"
                >
                  <b-form-input
                    id="register-nama"
                    v-model="nama"
                    name="register-nama"
                    :state="errors.length > 0 ? false:null"
                    placeholder="nama"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- provinsi -->
              <b-form-group
                label="Provinsi"
                label-for="register-provinsi"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Provinsi"
                  vid="provinsi"
                  rules="required"
                >
                  <b-form-select @input="getkabkot(kode_prov)"  v-model="kode_prov" :state="errors.length > 0 ? false:null">
                    <option
                        v-for="(dataprovinsi, k) in dataprovinsi"
                        :key="k"
                        :value="dataprovinsi.id"
                    >
                        {{ dataprovinsi.nama }}
                    </option>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- kabupaten -->
              <b-form-group
                label="Kabupaten"
                label-for="register-kabupaten"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Kabupaten/Kota"
                  vid="kabupaten"
                  rules="required"
                >
                  <b-form-select @input="getkecamatan(kode_kab)" v-model="kode_kab" :state="errors.length > 0 ? false:null">
                    <option
                        v-for="(datakabupaten, k) in datakabupaten"
                        :key="k"
                        :value="datakabupaten.id"
                    >
                        {{ datakabupaten.nama }}
                    </option>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- kecamatan -->
              <b-form-group
                label="Kecamatan"
                label-for="register-kecamatan"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Kecamatan"
                  vid="kecamatan"
                  rules="required"
                >
                  <b-form-select  v-model="kode_kec" :state="errors.length > 0 ? false:null">
                    <option
                        v-for="(datakecamatan, k) in datakecamatan"
                        :key="k"
                        :value="datakecamatan.id"
                    >
                        {{ datakecamatan.nama }}
                    </option>
                  </b-form-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- email -->
              <b-form-group
                label="Email"
                label-for="register-email"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  vid="email"
                  rules="required|email"
                >
                  <b-form-input
                    id="register-email"
                    v-model="email"
                    name="register-email"
                    :state="errors.length > 0 ? false:null"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- password -->
              <b-form-group
                label-for="register-password"
                label="Password"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Password"
                  vid="password"
                  rules="required"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="errors.length > 0 ? 'is-invalid':null"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label-for="register-password"
                
              >
              <vue-recaptcha @verify="markRecaptchaAsVerified" sitekey="6Le-wvkSAAAAAPBMRTvw0Q4Muexq9bi0DJwx_mJ-"></vue-recaptcha>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
                :disabled="invalid"
              >
                Sign up
              </b-button>
              <div><strong>{{ pleaseTickRecaptchaMessage }}</strong></div>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>Sudah punya akun ?</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;Login</span>
            </b-link>
          </p>

          <!-- divider -->
          <!-- <div class="divider my-2">
            <div class="divider-text">
              or
            </div>
          </div>

          <div class="auth-footer-btn d-flex justify-content-center">
            <b-button
              variant="facebook"
              href="javascript:void(0)"
            >
              <feather-icon icon="FacebookIcon" />
            </b-button>
            <b-button
              variant="twitter"
              href="javascript:void(0)"
            >
              <feather-icon icon="TwitterIcon" />
            </b-button>
            <b-button
              variant="google"
              href="javascript:void(0)"
            >
              <feather-icon icon="MailIcon" />
            </b-button>
            <b-button
              variant="github"
              href="javascript:void(0)"
            >
              <feather-icon icon="GithubIcon" />
            </b-button>
          </div> -->
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormSelect, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import axios from 'axios';
import { VueRecaptcha } from 'vue-recaptcha';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    VuexyLogo,
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    VueRecaptcha,
    // validations
    ValidationProvider,
    ValidationObserver,
    BFormSelect,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      username: '',
      userEmail: '',
      password: '',
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      // validation
      required,
      email,
      url: localStorage.getItem('baseapi'),
      dataprovinsi:[],
      datakabupaten:[],
      datakecamatan:[],
      recaptchaVerified: false,
      pleaseTickRecaptchaMessage: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.getprovinsi()
  },
  methods: {
    markRecaptchaAsVerified(response) {
      this.pleaseTickRecaptchaMessage = '';
      this.recaptchaVerified = true;
    },
    checkIfRecaptchaVerified() {
      if (!this.recaptchaVerified) {
        this.pleaseTickRecaptchaMessage = 'Please tick recaptcha.';
        return true; // prevent form from submitting
      }
      this.register()
    },
    getprovinsi(){
      axios.get(this.url+'wilayah/provinsi', {
      headers: {
          "Content-type": "application/json",
          }
      })
      .then(response => {
        this.dataprovinsi = response.data.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    getkabkot(id){
      axios.get(this.url+'wilayah/kabupaten?id_prov='+id, {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      
      .then(response => {
        this.datakabupaten = response.data.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    getkecamatan(id){
      // var nama_ruang = '';
      // var i = 0;
      // console.log(this.datakabupaten)
      // for (i in this.datakabupaten) {
      //       if (this.datakabupaten[i].kode_kab == id) {
      //           nama_ruang = this.datakabupaten[i].id;
      //           console.log(nama_ruang); // {a: 5, b: 6}
      //       }
      //   }
      // console.log(nama_ruang)
      // console.log(id)
      // console.log('nama id')
      axios.get(this.url+'wilayah/kecamatan?id_kab='+id, {
      headers: {
          "Content-type": "application/json",
          // "xth": this.pengguna.token,
          }
      })
      .then(response => {
        this.datakecamatan = response.data.data
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
    },
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          const nama=this.nama;
          const email = this.email;
          const password=this.password;
          const id_prov=this.kode_prov;
          const id_kab=this.kode_kab;
          const id_kec=this.kode_kec;
        axios
            .post(
            this.url+"user",
            {
                nama,
                email,
                password,
                id_prov,
                id_kab,
                id_kec,
            },
            {
            headers: {
                "Content-type": "application/json",
                // "xth": this.token,
                }
            }
            )
            .then(response => {
              this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: `Success`,
                      icon: 'CoffeeIcon',
                      variant: 'success',
                      text: "Berhasil Register",
                    },
                  })
                this.$router.push({ name: 'auth-login' })
            })
            .catch(error => {
              this.$refs.registerForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
